import React from "react";

function ExpoIcon({style}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={style.width}
    height={style.height}
    viewBox="0 0 24 24"
  >
    <path fill={style.fill} opacity={style.fillOpacity} d="M0 20.084c.043.53.23 1.063.718 1.778.58.849 1.576 1.315 2.303.567.49-.505 5.794-9.776 8.35-13.29a.761.761 0 011.248 0c2.556 3.514 7.86 12.785 8.35 13.29.727.748 1.723.282 2.303-.567.57-.835.728-1.42.728-2.046 0-.426-8.26-15.798-9.092-17.078-.8-1.23-1.044-1.498-2.397-1.542h-1.032c-1.353.044-1.597.311-2.398 1.542C8.267 3.991.33 18.758 0 19.77z"></path>
  </svg>
  );
}

export default ExpoIcon;